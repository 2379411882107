import * as React from "react"
import Seo from "@components/seo"

import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import JobsArea from '@containers/careers/jobs-area'
import FeaturesArea from "@containers/index-infotechno/features-area"
import ContactArea from "@containers/index-infotechno/contact-area"
import CTAArea from '@containers/global/cta-area/section-two'
import AnimatedSlider from "./elements/slider-infinite/slideranimated"
const IndexInfoTechno = ({ location }) => {
  
  return (
    <Layout location={location}>
      <Seo title="Home" />
      <Header />
      <main className="site-wrapper-reveal">
        <AnimatedSlider />

        <FeaturesArea />
        <JobsArea />
        {/* <CTAArea /> */}
        <ContactArea />
      </main>
      <Footer />
    </Layout>
  )
}

export default IndexInfoTechno
