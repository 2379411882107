import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import Section, { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Button from "@ui/button";
import { FormattedMessage } from "gatsby-plugin-react-intl"
import "./style.css"

const CTASection = ({
	sectionStyle,
	heading,
	text,
	ButtonOne,
	ButtonTwo,
	...props
}) => {
	const ctaData = useStaticQuery(graphql`
        query CtaTwoImgQuery {
            file(relativePath: {eq: "images/bg/cta-bg.png"}) {
                childImageSharp {
					gatsbyImageData(
						layout: CONSTRAINED
						width: 779
						height: 746
						placeholder: TRACED_SVG
						formats: WEBP
						quality: 100
					)
                }
            }
        } 
    `);

	const imageData = convertToBgImage(getImage(ctaData.file))

	return (
		<Section {...sectionStyle} bgImage={imageData}>
			<Container>
				<Row className="align-items-center text-lg-left text-center">
					<Col xl={8} lg={7}>
						<Heading {...heading}><FormattedMessage id="useful_monthly_book_professionals" /> <p>"Engineer to win"</p> </Heading>
					</Col>
					<Col xl={4} lg={5} className="text-center">
						<Button
						className="download-button"
							to="/"
							{...ButtonOne}
							icon="far fa fa-download"
							iconposition="left"
							icondistance="8px"
							iconsize="16px">
							<FormattedMessage id="download_book" />
                        </Button>
						
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

CTASection.propTypes = {
	heading: PropTypes.object
}

CTASection.defaultProps = {
	sectionStyle: {
		bgColor: '#f6f6f6',
		bgposition: 'top 35% right -68px',
		bgsize: 'auto',
		pt: '80px',
		pb: '80px',
		responsive: {
			medium: {
				pt: '60px',
				pb: '60px'
			},
			small: {
				pt: '40px',
				pb: '40px'
			}
		}
	},
	heading: {
		as: 'h3',
		child: {
			color: 'primary'
		},
		responsive: {
			medium: {
				mb: '10px'
			}
		}
	},
	text: {
		as: 'span',
		color: 'secondary'
	},
	ButtonOne: {
		skin: 'primary',
		m: '7px'
	},
	ButtonTwo: {
		skin: 'secondary',
		m: '7px'
	}
}


export default CTASection