import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "@ui/wrapper"
import {
  SectionWrap,
  JobList,
  JobItem,
  JobTitle,
  JobType,
  JobDesc,
} from "./style"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import "./style.css"

const JobsArea = () => {
  const getJobsData = useStaticQuery(graphql`
    query GET_JOBS_DATA {
      intro: careersJson(id: { eq: "careers-section-intro" }) {
        title
      }
      jobs: careersJson(id: { eq: "our-open-jobs" }) {
        jobs {
          id
          title
          type
          description
        }
      }
    }
  `)
  const {
    intro: { title },
    jobs: { jobs },
  } = getJobsData

  return (
    <SectionWrap>
      <Container>
        <Row justify="center">
          <Col lg={7}>
            <h3 style={{ textAlign: "center", marginBottom: "42px" }}>
              <FormattedMessage id="user-suggestion" />
            </h3>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <JobList>
              <JobItem isEven={false}>
                <Row>
                  
                  <Col lg={9}>
                    <JobDesc className="reflect-text mt-3">
                      <FormattedMessage id="user_suggestion_desc" />
                     

                    </JobDesc>
                    
                  </Col>
                  <Col lg={3}>
                    <a href="suggestion" className="contactus-button "><FormattedMessage id="send_suggestion" /></a></Col>
                </Row>
              </JobItem>
            </JobList>
          </Col>
         
        
        </Row>
      </Container>
    </SectionWrap>
  )
}

export default JobsArea
